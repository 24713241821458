import { isEmpty } from 'lodash-es';
import BaseView from '../../../js/base-view';

const CLASS_IS_OPEN = 'is-open';
const CLASS_IS_ACTIVE = 'is-active';
const CLASS_HAS_RESULTS = 'has-results';

const SELECTOR_INPUT = '.input__field';
const SELECTOR_SUGGESTION = '.suggestion-list';
const SELECTOR_SUGGESTION_ITEM = '.suggestion__item';
const SELECTOR_N_RESULTS = '.search-input__nresults';

const DEFAULT_CONFIG = {
	texts: {
		result: 'result',
		results: 'results',
	},
};

export default class SearchInput extends BaseView {
	init() {
		this.refs = {
			input: this.element.querySelector( SELECTOR_INPUT ),
			nresults: this.element.querySelector( SELECTOR_N_RESULTS ),
			suggestions: this.element.querySelector( SELECTOR_SUGGESTION ),
		};

		this.state = {
			value: null,
			activeEl: null,
			currentFocus: -1,
			isOpen: false,
		};

		this.config = {

			...DEFAULT_CONFIG,
			...( ( window.supt && window.supt.searchInput ) ? window.supt.searchInput : {} ),
		};

		this.bindEvents();

		return this;
	}

	bindEvents() {
		this.on( 'input', SELECTOR_INPUT, this.onInputChange.bind( this ) );
		this.on( 'submit', this.onSubmit.bind( this ) );

		this.closeSearchHandler = this.handleSearchFocus.bind( this );
		document.addEventListener( 'click', this.closeSearchHandler );

		this.on( 'keydown', SELECTOR_INPUT, this.handleSuggestionFocus.bind( this ) );
		this.on( 'click', SELECTOR_SUGGESTION_ITEM, this.onSuggestionItemClick.bind( this ) );
	}

	// #######################
	// #region EVENT LISTENERS
	// #######################

	onInputChange( ev ) {
		this.state.value = ev.target.value;
		this.trigger( 'input:change', { detail: this.state.value }, false );

		this.state.currentFocus = -1;
	}

	onSubmit( ev ) {
		ev.preventDefault();
		this.state.value = this.refs.input.value;
		this.submit();
	}

	handleSearchFocus( ev ) {
		if ( ! this.element.contains( ev.target ) ) {
			this.trigger( 'close', null );
		}
	}

	// handles with keyboard action
	handleSuggestionFocus( ev ) {
		if ( ! this.state.isOpen ) {
			return;
		}

		// eslint-disable-next-line default-case
		switch ( ev.key ) {
			case 'ArrowDown':
				ev.preventDefault();
				this.state.currentFocus += 1;
				this.addActive();
				break;

			case 'ArrowUp':
				ev.preventDefault();
				this.state.currentFocus -= 1;
				this.addActive();
				break;

			case 'Enter':
				if ( this.state.activeEl ) {
					this.selectSuggestion();
					ev.preventDefault();
				}
				break;

			case 'Escape':
				this.closeSuggestions();
				break;
		}
	}

	onSuggestionItemClick({ target }) {
		this.state.activeEl = target;
		this.selectSuggestion();
	}

	// #######################
	// #endregion
	// #######################

	/**
	 * redefine current focus according with list position
	 */
	addActive() {
		const items = this.refs.suggestions.children;

		if ( this.state.currentFocus >= items.length ) {
			this.state.currentFocus = items.length - 1;
		}
		else {
			this.removeActive();

			if ( this.state.currentFocus < 0 ) {
				this.state.currentFocus = -1;
				this.state.activeEl = null;
			}

			// apply focus on current item
			else {
				this.state.activeEl = items[ this.state.currentFocus ];
				this.state.activeEl.classList.add( CLASS_IS_ACTIVE );

				this.scrollToSuggestion();
			}
		}
	}

	removeActive() {
		if ( this.state.activeEl ) {
			this.state.activeEl.classList.remove( CLASS_IS_ACTIVE );
		}
	}

	/**
	 * Scroll Suggestion list to be sure to show
	 * the active item inside it's view area
	 */
	scrollToSuggestion() {
		const { offsetTop, offsetHeight } = this.state.activeEl;
		if ( offsetTop < this.refs.suggestions.scrollTop || // item is above
			( offsetTop + offsetHeight ) > this.refs.suggestions.offsetHeight ) { // item is below
			this.refs.suggestions.scrollTop = offsetTop;
		}
	}

	/**
	 * Remove all items of the suggestion list
	 */
	cleanSuggestions() {
		while ( this.refs.suggestions.lastChild ) {
			this.refs.suggestions.removeChild( this.refs.suggestions.lastChild );
		}

		this.state.currentFocus = -1;
		this.state.activeEl = null;
	}

	/**
	 * Clean & close the suggestion list
	 */
	closeSuggestions() {
		this.element.classList.remove( CLASS_IS_OPEN );
		this.cleanSuggestions();

		this.state.isOpen = false;
	}

	selectSuggestion() {
		const { value } = this.state.activeEl.dataset;
		this.refs.input.value = value;
		this.state.value = value;
		this.closeSuggestions();
		this.submit();
	}

	submit() {
		this.trigger( 'input:submit', { detail: this.state.value }, false );
		this.closeSuggestions();
	}

	// #######################
	// #region PUBLIC METHODS
	// #######################

	updateSuggestion( items = [] ) {
		// make sure search input is not empty
		if ( this.refs.input.value.length === 0 ) {
			this.closeSuggestions();
		}
		else {
			this.cleanSuggestions();

			// checks annuaire items that match the search
			// checks only full name
			items.forEach( ( item ) => {
				const li = document.createElement( 'LI' );
				li.setAttribute( 'class', 'suggestion__item' );
				li.dataset.value = item.value;

				const value = item.value.replace( new RegExp( this.state.value.trim(), 'gi' ), `<b>${ this.state.value.trim() }</b>` );
				li.innerHTML = value + ( isEmpty( item.category ) ? '' : ` <em>(${ item.category })</em>` );
				this.refs.suggestions.appendChild( li );
			} );

			// show message when no results were found
			if ( items.length === 0 ) {
				this.refs.nresults.innerHTML = '';
				const item = document.createElement( 'LI' );
				item.setAttribute( 'class', 'no-results' );
				item.textContent = 'No results found';
				this.refs.suggestions.appendChild( item );
			}

			// add class that activates the styles when suggestion-list opened
			this.state.isOpen = true;
			this.element.classList.add( CLASS_IS_OPEN );
		}
	}

	/**
	 * update number of results
	 *
	 * @param {number} nbResults
	 */
	updateNbResults( nbResults = 0 ) {
		this.refs.nresults.textContent = `${ nbResults } ${ this.config.texts[ ( nbResults > 1 ? 'results' : 'result' ) ] }`;
		this.element.classList[ ( nbResults > 0 ? 'add' : 'remove' ) ]( CLASS_HAS_RESULTS );
	}

	reset() {
		// Todo reset something ?
	}

	// #######################
	// #endregion
	// #######################

	destroy() {
		super.destroy();

		document.addEventListener( 'click', this.closeSearchHandler );
	}
}
